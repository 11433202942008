.homepage-container{
    width: 100%;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
	align-items: center;
    background-image: url('./dotted.png');

    background-color: #333333;
    color: white;
}

.homepage-topbar{
    width: 100%;
    max-width: 1120px;
    box-sizing: border-box;
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
}

.get-touch-container>a{
    color: #8A8A8A;
    font-family: "DM Sans";
    font-size: 15px;
    text-decoration: none !important;
    font-weight: 400;
}

.login-link-container>a{
    text-decoration: none !important;
    color: white;
    font-family: "DM Sans";
    font-size: 15px;
    font-weight: 400;
}

.greeting-creator{
    font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	line-height: 25px;
	text-align: center;
	color: white;
}

.homepage-header1{
    margin-top: 15px;

    font-family: 'DM Sans';
	font-style: normal;
	font-weight: 600;
    font-size: 40px;
    line-height: 54px;
	text-align: center;
	color: #F1F1F1;
}

.waitlist-button{
    width: 217px;
    height: 48px;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 15px 40px 15px 40px;
    background-color: #F1F1F1;

    text-decoration: none !important;

    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.waitlist-button-text{
    color: #54595f;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    font-family: "DM Sans";
    text-decoration: none !important;
}

.right-arrow-icon{
    display: inline;
    height: 16px;
    width: 16px;
}

@media (max-width: 766px) {
    .homepage-header1 {
        font-size: 20px;
        line-height: 27px;
    }
  }

  .home-spacer{
      height: 50px;
  }