.display-container {
	width: 100%;
	min-height: 100%;
	margin: 0 auto;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	/* padding-bottom: 55px; */
	border: 2px solid white;
	background-image: url('../../icons/profile_bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.width-spacer{
	height: 95px;
	flex-shrink: 0;
}

.display-details-card {
	width: min(100%, 450px);
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 2px 25px 2px rgba(0, 0, 0, 0.08);
	border-radius: 35px;
	padding-bottom: 66px;
	background-color: white;
}

.display-image {
	width: 150px;
	height: 150px;
	border-radius: 75px;
	box-shadow: 0px 2px 30px 2px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	margin-bottom: 25px;
	margin-top: -75px;
}

.display-influencer-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 42px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.verified-icon {
	width: 29px;
	height: 29px;
}

.platform-links-container {
	margin-top: 18px;
	display: flex;
	gap: 45px;
}

.platform-link {
	width: 60px;
	height: 60px;
	/* border: 1px solid black; */
	background: #f4f4f4;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border: none;
	border-radius: 10px;

	text-decoration: none;
	line-height: 60px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.platform-link > img {
	width: 24px;
	height: 24px;
}

.collaberate-button {
	margin-top: 39px;
	width: 83%;
	height: 48px;

	background: #2f2f2e;
	border: 1px solid #000000;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 20px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;
	text-align: center;
	color: white;
}

.custom-link-button {
	margin-top: 25px;
	width: 83%;
	height: 48px;
	background: #f4f4f4;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.06);
	border-radius: 20px;
	border: none;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	text-align: center;

	text-decoration: none;
	line-height: 48px;

	color: #b9b9b9;
}

.flex-spacer{
	flex-grow: 1;
}

.bottom-floater-set{
	justify-self: end;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.create-account-button {
	width: 100px;
	height: 24px;
	background-color: #228be6;
	border-radius: 12px;

	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 24px;
	margin-bottom: 30px;
	color: #f1f1f1;
}

.just-collab-icon {
	height: 43px;
	width: 180px;
	margin-bottom: -10px;
}

.link-display-subtext {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 8px;
	line-height: 12px;
	color: white;
}
