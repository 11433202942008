.proposal-container {
	width: min(100%, 450px);
	margin: 0 auto;
	min-height: 100%;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
}

.proposal-collab-header {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 60px;

	color: #060606;

	margin-top: 130px;
}

.proposal-label-text {
	margin-top: 50px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;

	color: #060606;
}

.proposal-input-field {
	margin-top: 42px;
}

.proposal-input-field::placeholder {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;

	color: #060606;
}

.proposal-input-text-area {
	margin-top: 42px;
}

.proposal-input-text-area::placeholder {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;

	color: #060606;
}

.platform-selector-container {
	width: 100%;
	display: flex;
	/* justify-content: space-between; */
	margin-top: 25px;
	/* flex-wrap: wrap; */
	gap: 30px;
	/* border: 1px solid black; */
}

.unselected-platform-button {
	width: 51px;
	height: 48px;
	background: #f4f4f4;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.selected-platform-button {
	width: 51px;
	height: 48px;
	background: #909090;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	display: flex;
	justify-content: center;
	align-items: center;
}

.unselected-platform-button > img,
.selected-platform-button > img {
	width: 22px;
	height: 22px;
}

.proposal-submit-button {
	width: 100%;
	margin-bottom: 82px;
}

.proposal-consent-checkbox {
	width: 95%;
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	/* border: 1px solid blue; */
}

.proposal-consent-checkbox-label {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	text-align: left;

	color: #060606;
}
