.login-container {
	width: min(100%, 450px);
	min-height: 100%;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	box-sizing: border-box;
}

.login-input-label {
	margin-top: 190px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
	align-self: flex-start;

	color: #060606;
}

.login-input-field {
	margin-top: 20px;
}

.password-mismatch-border {
	border: 2px solid red;
}

.login-display-subtext {
    position: absolute;
    bottom: 20px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;

	color: #5e5e5e;
	align-self: center;
}