.base-styled-button {
	margin-top: 35px;
	width: 100%;
	height: 72px;

	background: #212121;
	border-radius: 17px;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;

	color: #ffffff;
}

.base-styled-input-field-container {
	/* gap: 5px; */
	background: #f2f3f7;
	border-radius: 17px;
	border: none;
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
}

.base-styles-input-icon-holder {
	min-width: 17px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.base-styles-input-icon-holder > img {
	width: 40px;
	height: 40px;
	margin-left: 30px;
}

.base-styled-input-field {
	flex: 1;
	width: 100%;
	padding: 26px;
	border: none;
	border-radius: 17px;
	background-color: #f2f3f7;
}

.base-styled-input-field,
.base-styled-input-field::placeholder,
.base-styled-input-field::-webkit-input-placeholder {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;

	color: #060606;
	/* color: blue; */
	text-align: left;
}

input:focus {
	outline: none;
}

.base-styled-input-text-area-label-holder {
	display: flex;
	justify-content: left;
	align-items: center;
	width: 100%;

	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;

	color: #060606;
}

.base-styled-input-text-area-label-holder > img {
	width: 33px;
	height: 26px;
	margin-right: 11px;
}

.base-styled-input-text-area {
	margin-top: 25px;
	padding: 26px 27px;
	/* gap: 5px; */
	background-color: #f2f3f7;
	border-radius: 15px;
	border: none;
	width: 100%;
	height: 72px;
	box-sizing: border-box;

	/* height: 50px; */
}

.base-styled-input-text-area:focus {
	outline: none;
}

.base-styled-input-text-area,
.base-styled-input-text-area::placeholder {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;

	color: #060606;
}


.error-message{
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	text-align: left;

	color: red;
}

.red-border{
	border: 1px solid red;
}
